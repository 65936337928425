import { msalConfig, loginScopes } from '@/js/auth/auth-config';
import axios from 'axios';
import { WEBSERVER_URL } from '@/env';

import * as msal from "@azure/msal-browser";
import { InteractionRequiredAuthError } from '@azure/msal-common';

// Based on https://github.com/ahermant/vue-msal-browser, a vue wrapper for Microsofts MSAL js library

const openIdScope = loginScopes[0];

class msalWrapper {
	instance = null;

	static async create() {
		const bootResponse = await axios.get(WEBSERVER_URL + '/boot');
		msalConfig.auth.clientId = bootResponse.data.KOGNI_CLIENT_ID;
		this.instance = new msalPlugin(msalConfig);
	}
}

class msalPlugin extends msal.PublicClientApplication {
	constructor(options) {
		super(options);
		this.config.graph = options.graph || {};
	}

	async acquireToken(scopes) {
		const tokenRequest = { scopes };

		try {
			return await this.acquireTokenSilent(tokenRequest);
		} catch (error) {
			if (error instanceof InteractionRequiredAuthError) {
				console.log('Silent token retrieval failed, redirecting', error);
				return this.acquireTokenRedirect(tokenRequest);
			}
			console.log('Error on token retrieval', tokenRequest, this.getActiveAccount());
			console.error(error);
		}
	}

	async accessToken(scope) {
		const token = await this.acquireToken(Array.isArray(scope) ? scope : [scope]);
		return token.accessToken;
	}

	async accessAuthorization(scope) {
		const token = await this.acquireToken(Array.isArray(scope) ? scope : [scope]);
		return token ? `${token.tokenType} ${token.accessToken}` : null;
	}

	async idToken() {
		const token = await this.acquireToken(loginScopes);
		return token?.idToken;
	}

	async idAuthorization() {
		// Test to see if things work better when only using one scope for retrieving
		const token = await this.acquireToken([openIdScope]);
		return token ? `${token.tokenType} ${token.idToken}` : null;
	}

	async login() {
		if (this.getActiveAccount()) {
			return;
		}
		let loginResponse = null;
		const silentRequest = { scopes: [openIdScope] }; // Attempt only openid scope
		try {
			const response = await this.handleRedirectPromise();
			console.log(response);
			if (response) {
				this.setActiveAccount(response.account);
				return;
			}
			loginResponse = await this.loginRedirect(silentRequest);
		} catch (error) {
			console.log('Error on login after redirect', error, this.getActiveAccount());
		}

		if (loginResponse) {
			this.setActiveAccount(loginResponse.account);
		}
	}
}

await msalWrapper.create();
export default msalWrapper.instance;
